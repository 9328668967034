// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-analysis-jsx": () => import("./../../../src/pages/analysis.jsx" /* webpackChunkName: "component---src-pages-analysis-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-company-jsx": () => import("./../../../src/pages/company.jsx" /* webpackChunkName: "component---src-pages-company-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-platform-jsx": () => import("./../../../src/pages/platform.jsx" /* webpackChunkName: "component---src-pages-platform-jsx" */),
  "component---src-pages-privacy-and-cookie-policy-jsx": () => import("./../../../src/pages/privacy-and-cookie-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-and-cookie-policy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-suppliers-jsx": () => import("./../../../src/pages/suppliers.jsx" /* webpackChunkName: "component---src-pages-suppliers-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-templates-case-study-jsx": () => import("./../../../src/templates/case-study.jsx" /* webpackChunkName: "component---src-templates-case-study-jsx" */)
}

